.services {
    overflow: hidden;
    margin: 0 auto 100px;
}

.services__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 43px;
    margin: 0 0 10px;
}

.services-block {
    max-width: 565px;
    margin-top: 40px;
}

.services-block:first-child {
    margin-top: 0;
}

.services-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.services-list__title {
    max-width: 600px;
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    padding-left: 49px;
    margin: 0 0 20px;
}

.services-list__item {
    max-width: 600px;
    position: relative;
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 15px;
    padding-left: 50px;
    z-index: 1;
}

.services-list__item:before {
    width: 9px;
    height: 9px;
    position: absolute;
    top: 29%;
    left: 0;
    z-index: 2;
    content: "";
    border-width: 3px;
    border-radius: 3000px;
    background-position: center center;
    border-color: #fff538;
    border-style: solid;
    box-shadow: 0 0 15px 0 #FAD700;
}

.services-list__item:last-of-type {
    margin-bottom: 0;
}

.services__blocks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    right: -25%;
    transition: right 1s ease;
}

.services__blocks-container_active {
    right: 0;
}

.services__columns {
    display: flex;
    gap: 40px;
}

.services__block-column {
    display: flex;
    flex-direction: column;
}

.services__btn-sign-up {
    margin-top: 50px;
}

@media screen and (max-width: 900px) {
    .services__columns {
        flex-direction: column;
        gap: 40px;
    }

    .services-block {
        box-sizing: border-box;
    }

    .services-list__title {
        text-align: center;
        padding-left: 0;
    }

    .services-list__item {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .services__title {
        text-align: center;
    }

    .services-list__title {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 15px;
    }

    .services-list__item {
        font-size: 16px;
        padding-left: 30px;
    }

    .services-list__item:before {
        width: 6px;
        height: 6px;
    }
}

@media screen and (max-width: 500px) {
    .services {
        margin-bottom: 60px;
    }

    .services__title {
        font-size: 34px;
        margin-bottom: 10px;
    }

    .services-list__title {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 15px;
    }

    .services-list__item {
        font-size: 14px;
        padding-left: 30px;
        line-height: normal;
    }

    .services-list__item:before {
        width: 5px;
        height: 5px;
        top: 34%;
    }
}

@media screen and (max-width: 400px) {
    .services__title {
        font-size: 30px;
        margin-bottom: 0;
    }
}