.card {
    display: flex;
    flex-direction: column;
    gap: 25px;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 15px;
    background-color: #4f4f4e;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
}

.card_size_small {
    width: 48%;
}

.card_size_all-available {
    width: 100%;
}

.card_border-color_yellow {
    border-color: #FAD700;
    box-shadow: 15px 15px 30px 0 rgba(255,245,56,0.2);
}

.card_border-color_purple {
    border-color: #6154dc;
    box-shadow: 15px 15px 30px 0 rgba(97,84,220,0.2);
}

.card_border-color_blue {
    border-color: #3cf0fa;
    box-shadow: 15px 15px 30px 0 rgba(60,240,250,0.2);
}

.card__image-container {
    display: flex;
    justify-content: center;
    border-radius: 15px;
    background-color: #2b2b2a;
}

.card__image {
    width: 90%;
}


.card__text {
    text-align: center;
    font-size: 20px;
    line-height: 31px;
    margin: 0;
}

@media screen and (max-width: 900px) {
    .card__text {
        font-size: 18px;
    }
}

@media screen and (max-width: 640px) {
    .card {
        gap: 10px;
    }

    .card_size_small {
        width: 100%;
        line-height: 25px;
    }
}

@media screen and (max-width: 500px) {
    .card__text {
        font-size: 12px;
        line-height: 20px;
    }
}