.section {
    display: flex;
    width: 95%;
    margin: 0 auto;
}

.section__container_size_small {
    max-width: 560px;
}

.section__container_size_medium {
    max-width: 692px;
}

.section__container_size_big {
    max-width: 692px;
}

.section__container_size_big {
    max-width: 692px;
}

.section__container_size_extra {
    max-width: 960px;
}

.section__title {
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.section__title_size_tiny {
    font-size: 24px;
    line-height: 36px;
}

.section__title_size_small {
    font-size: 28px;
    line-height: 43px;
}

.section__title_size_medium {
    font-size: 32px;
    line-height: 50px;
}

.section__title_size_big {
    font-size: 50px;
    line-height: 78px;
}

.section__title_size_unique {
    font-size: 50px;
    line-height: 78px;
}

.section__title_align_left {
    text-align: left;
}

.section__title_align_right {
    width: 85%;
    text-align: right;
}

@media screen and (max-width: 768px) {
    .section__title_size_unique {
        font-size: 36px;
        line-height: 48px;
    }

    .section__title_size_big {
        font-size: 44px;
        line-height: 74px;
    }

    .section__title_size_medium {
        font-size: 28px;
        line-height: 42px;
    }

    .section__title_size_small {
        font-size: 24px;
        line-height: 35px;
    }

    .section__title_size_tiny {
        font-size: 20px;
    }
}

@media screen and (max-width: 510px) {
    .section__title_size_unique {
        font-size: 28px;
        line-height: 40px;
    }

    .section__title_size_big {
        font-size: 36px;
        line-height: 50px;
    }

    .section__title_size_medium {
        font-size: 20px;
        line-height: 32px;
    }

    .section__title_size_small {
        font-size: 19px;
        line-height: 30px;
    }

    .section__title_size_tiny {
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    .section__title_size_unique {
        font-size: 22px;
        line-height: 30px;
    }

    .section__title_size_big {
        font-size: 26px;
        line-height: 30px;
    }

    .section__title_size_medium {
        font-size: 20px;
    }

    .section__title_size_small {
        font-size: 18px;
        line-height: 26px;
    }

    .section__title_size_tiny {
        font-size: 16px;
    }
}