.header {
    padding-top: 15px;
}

.header__container {
    width: 90%;
    max-width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.header__btn-show-navigation {
    width: 44px;
    height: 44px;
    border: none;
    background-color: inherit;
    background-image: url("../../images/menu-icon.svg");
    background-size: cover;
    display: none;
    cursor: pointer;
}

.header__contacts {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header__work-hours {
    font-size: 16px;
    margin: 0;
}

.header__phone-number {
    font-size: 18px;
    color: #FAD700;
    font-weight: 600;
    margin: 0;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .header__container {
        padding: 0 20px;
    }
    .header__btn-show-navigation {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .header__btn-show-navigation {
        width: 32px;
        height: 32px;
    }
}

@media screen and (max-width: 500px) {
    .header__work-hours {
        font-size: 12px;
    }

    .header__phone-number {
        font-size: 16px;
    }
}

@media screen and (max-width: 350px) {
    .header__work-hours {
        font-size: 9px;
    }

    .header__phone-number {
        font-size: 13px;
    }
}