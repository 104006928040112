.logo {
    display: flex;
    gap: 5px;
    align-items: center;
}

.logo__title {
    font-weight: 700;
    font-size: 40px;
    margin: 0;
}

.logo__picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: spin 6s infinite linear;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media screen and (max-width: 768px) {
    .logo__title {
        font-size: 34px;
    }

    .logo__picture {
        width: 34px;
        height: 34px;
    }
}

@media screen and (max-width: 500px) {
    .logo__title {
        font-size: 26px;
    }

    .logo__picture {
        width: 26px;
        height: 26px;
    }
}