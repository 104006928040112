.promo {
    margin: 100px auto 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promo__title {
    font-size: 52px;
    font-weight: 700;
    line-height: 62px;
    margin: 0 0 30px;
    text-align: center;
}

.promo__subtitle {
    font-size: 22px;
    margin: 0 0 40px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .promo__title {
        width: 90%;
        font-size: 34px;
        line-height: 50px;
    }

    .promo__subtitle {
        width: 90%;
        font-size: 18px;
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 500px) {
    .promo__title {
        min-width: 300px;
        width: 90%;
        font-size: 22px;
        line-height: 35px;
        margin-bottom: 20px;
    }

    .promo__subtitle {
        width: 90%;
        font-size: 14px;
    }

    .promo__btn-sign-up {
        box-sizing: border-box;
        max-width: 280px;
        margin: 0 auto;
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .promo {
        margin: 60px 0 50px;
    }

    .promo__title {
        font-size: 22px;
    }
}