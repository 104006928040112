.app {
  font-family: "Open Sans", serif;
  color: #fcf9f9;
  background-color: #161617;
  min-height: 100vh;
  overflow: hidden;
}

input {
  border: none;
  border-radius: 10px;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: opacity .13s cubic-bezier(0, 0, .23, 1);
}

a:hover {
  opacity: 0.8;
}

button {
  transition: opacity .13s cubic-bezier(0, 0, .23, 1);
}

button:hover {
  transition: opacity .13s cubic-bezier(0, 0, .23, 1);
}

.span-additional-color {
  color: #FAD700;
}

.span-yellow-border {
  text-shadow: 0 0 5px #FAD700;
}

.fieldset {
  padding: 0;
  border: none;
}