.why-us {
    position: relative;
    margin: 0 auto;
}

.why-us__cards-container {
    position: relative;
    z-index: 1;
    width: 82%;
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin: 40px auto 100px;
}

.why-us__cards-row {
    display: flex;
    justify-content: space-between;
}

.cosmonaut {
    position: absolute;
    top: 36%;
    right: -10%;
    width: 270px;
    animation: floatingAnimation 10s infinite;
    z-index: 0;
}

@keyframes floatingAnimation {
    0% {
        transform: translate(0, -50%);
    }
    25% {
        transform: translate(-6%, -54%);
    }
    50% {
        transform: translate(0, -50%);
    }
    75% {
        transform: translate(4%, -46%);
    }
    100% {
        transform: translate(0, -50%);
    }
}

@media screen and (max-width: 900px) {
    .cosmonaut {
        top: 36%;
        right: -7%;
        width: 230px;
    }
}

@media screen and (max-width: 768px) {
    .cosmonaut {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .why-us__cards-row {
        width: 100%;
        flex-direction: column;
        gap: 40px;
    }
}

@media screen and (max-width: 500px) {
    .why-us__cards-container{
        margin-top: 30px;
        margin-bottom: 60px;
    }
}