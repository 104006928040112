.footer__section {
    width: 90%;
    max-width: 960px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid #454548;
    border-bottom: 1px solid #454548;
    margin: 30px auto 0;
}

.footer__section:last-of-type {
    border-bottom: none;
}

.footer__links-column {
    width: 280px;
}

.footer__links-list {
    display: flex;
    flex-direction: column;
    gap: 3px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer__links-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 10px;
}

.footer__link {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
}

@media screen and (max-width: 768px) {
    .footer__copyright {
        font-size: 16px;
    }

    .footer__section {
        flex-direction: column;
        gap: 15px;
    }
}

@media screen and (max-width: 500px) {
    .footer__copyright {
        font-size: 13px;
    }
}