.difficulties {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80%;
    margin: 0 auto 100px;
}

@media screen and (max-width: 500px) {
    .difficulties {
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 400px) {
    .difficulties {
        margin-bottom: 0;
    }
}