.list {
    padding-left: 20px;
    margin: 0 0 40px;
    width: 90%;
}

.list:last-child {
    margin-bottom: 0;
}

.list__title {
    font-size: 28px;
    width: 80%;
    margin: 0 0 5px;
    color: #FAD700;
}

.list__item {
    font-size: 22px;
    padding: 10px 10px 0 10px;
}

.list__item::marker {
    color: #FAD700;
}

@media screen and (max-width: 450px) {
    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 95%;
        padding-left: 0;
        list-style: none;
    }

    .list__title {
        width: 100%;
        text-align: center;
        list-style: none;
        margin-bottom: 15px;
    }

    .list__item {
        font-size: 14px;
        padding: 0 0 15px;
        list-style: square;
    }

    .list_type_services {
        padding-left: 20px;
    }
}

@media screen and (max-width: 500px) {
    .list {
        align-items: flex-start;
        margin: 0 auto;
    }

    .list__title {
        font-size: 24px;
    }

    .list__item {
        font-size: 16px;
    }
}

@media screen and (max-width: 400px) {
    .list__title {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .list__item {
        font-size: 14px;
    }
}