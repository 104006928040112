.button {
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    text-decoration: none;
}

.button_type_primary {
    font-size: 20px;
    line-height: 24px;
    color: #161617;
    background-color: #FAD700;
    padding: 24px 54px;
    clip-path: polygon(0 0,93% 0,100% 26%,100% 100%,7% 103%,0% 77%);
}

.button_type_secondary {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: white;
    background-color: #161617;
    border: 1px solid #FAD700;
    padding: 30px 0;
    transition: color .2s ease-in, background-color .2s ease-in;
    cursor: auto;
    margin-top: 8px;
}

.button_type_secondary-active {
    color: #161617;
    background-color: #FAD700;
    border: none;
}

@media screen and (max-width: 768px) {
    .button_type_secondary {
        max-width: 200px;
        padding: 10px;
        white-space: normal;
    }
}

@media screen and (max-width: 500px) {
    .button {
        padding: 13px 40px;
        font-size: 14px;
    }

    .button_type_secondary {
        padding: 13px 10px;
        font-size: 14px;
        line-height: 18px;
    }
}