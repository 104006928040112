.how-it-works {
    padding: 30px 0 50px;
    border-top: 3px solid #454548;
    border-bottom: 3px solid #454548;
    margin: 0 auto 60px;
}

.round-items__items-container {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.round-items__item-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.round-items__item-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 200px;
    height: 200px;
    font-size: 22px;
    text-align: center;
    color: black;
    background-color: #FAD700;
    border-radius: 50%;
    margin: 0;
    z-index: 1;
}

.round-items__item-title:after {
    position: absolute;
    content: "";
    border: 20px solid transparent; border-left: 20px solid #FAD700;
    top: 40%;
    right: -46%;
    z-index: 2;
}

.round-items__item-title_size_little {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 90%;
    height: 100%;
    font-size: 14px;
}

.round-items__item-container:last-of-type .round-items__item-title:after {
    border: none;
}

.round-items__item-description {
    font-size: 17px;
    text-align: center;
    margin: 0;
}

.round-items__arrow {
    display: none;
    transform: rotate(180deg);
    width: 40px;
    height: 40px;
    background-image: url("../../images/triangle-filled-svgrepo-com.svg");
    background-size: cover;
}

.round-items__item-title_no-arrow:after {
    border: none;
}

@media screen and (max-width: 900px) {
    .round-items__item-title {
        font-size: 20px;
        width: 160px;
        height: 160px;
    }
}

@media screen and (max-width: 768px) {
    .round-items__items-container_size_little {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .round-items__item-title:after {
        top: 36%;
        right: -51%;
    }

    .round-items__item-title {
        font-size: 16px;
        width: 130px;
        height: 130px;
    }

    .round-items__item-description {
        font-size: 14px;
    }

    .round-items__item-title_size_little {
        width: 100%;
        padding: 35% 0;
    }
}

@media screen and (max-width: 600px) {
    .how-it-works {
        margin-bottom: 40px;
    }

    .round-items__items-container {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .round-items__items-container_size_little {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .round-items__item-container {
        position: relative;
        max-width: 417px;
        width: 70%;
        min-width: 300px;
    }

    .round-items__item-title {
        font-size: 20px;
        width: 210px;
        height: 210px;
    }

    .round-items__item-title:after {
        display: none;
    }

    .round-items__item-description {
        font-size: 16px;
    }

    .round-items__arrow {
        display: block;
    }

    .round-items__item-title_size_little {
        font-size: 16px;
        width: 62%;
        padding: 20% 0;
    }
}

@media screen and (max-width: 450px) {
    .round-items__item-title {
        width: 170px;
        height: 170px;
    }

    .round-items__item-title_size_little {
        font-size: 14px;
        padding: 20% 0;
    }

    .round-items__item-description {
        font-size: 14px;
    }
}